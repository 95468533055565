import { default as _91_46_46_46slug_93eHH69jK87GMeta } from "/vercel/path0/pages/activities/[...slug].vue?macro=true";
import { default as index0YwGkZGccMMeta } from "/vercel/path0/pages/activities/index.vue?macro=true";
import { default as calendarGNujQtdH8MMeta } from "/vercel/path0/pages/calendar.vue?macro=true";
import { default as confirmLoginxjRChUvkacMeta } from "/vercel/path0/pages/confirmLogin.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as _91_46_46_46slug_93QJUHw5g7hHMeta } from "/vercel/path0/pages/info/[...slug].vue?macro=true";
import { default as logint0AWlhQgM0Meta } from "/vercel/path0/pages/login.vue?macro=true";
import { default as _91_46_46_46slug_93Zf86rRDqqNMeta } from "/vercel/path0/pages/news/[...slug].vue?macro=true";
import { default as indexxeWP9UFz4RMeta } from "/vercel/path0/pages/news/index.vue?macro=true";
import { default as _91_46_46_46slug_93PiEiFSMuFXMeta } from "/vercel/path0/pages/pages/[...slug].vue?macro=true";
import { default as overview3h72kTsVpVMeta } from "/vercel/path0/pages/profile/overview.vue?macro=true";
import { default as settingsOrFU0tYfAWMeta } from "/vercel/path0/pages/profile/settings.vue?macro=true";
import { default as resetpasswordtzshyXK4M4Meta } from "/vercel/path0/pages/resetpassword.vue?macro=true";
import { default as signupY93ILdpGpbMeta } from "/vercel/path0/pages/signup.vue?macro=true";
import { default as _91_46_46_46slug_93x81oV3TSaaMeta } from "/vercel/path0/pages/stories/[...slug].vue?macro=true";
import { default as indexTZwyp4rywJMeta } from "/vercel/path0/pages/stories/index.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93eHH69jK87GMeta?.name ?? "activities-slug",
    path: _91_46_46_46slug_93eHH69jK87GMeta?.path ?? "/activities/:slug(.*)*",
    meta: _91_46_46_46slug_93eHH69jK87GMeta || {},
    alias: _91_46_46_46slug_93eHH69jK87GMeta?.alias || [],
    redirect: _91_46_46_46slug_93eHH69jK87GMeta?.redirect,
    component: () => import("/vercel/path0/pages/activities/[...slug].vue").then(m => m.default || m)
  },
  {
    name: index0YwGkZGccMMeta?.name ?? "activities",
    path: index0YwGkZGccMMeta?.path ?? "/activities",
    meta: index0YwGkZGccMMeta || {},
    alias: index0YwGkZGccMMeta?.alias || [],
    redirect: index0YwGkZGccMMeta?.redirect,
    component: () => import("/vercel/path0/pages/activities/index.vue").then(m => m.default || m)
  },
  {
    name: calendarGNujQtdH8MMeta?.name ?? "calendar",
    path: calendarGNujQtdH8MMeta?.path ?? "/calendar",
    meta: calendarGNujQtdH8MMeta || {},
    alias: calendarGNujQtdH8MMeta?.alias || [],
    redirect: calendarGNujQtdH8MMeta?.redirect,
    component: () => import("/vercel/path0/pages/calendar.vue").then(m => m.default || m)
  },
  {
    name: confirmLoginxjRChUvkacMeta?.name ?? "confirmLogin",
    path: confirmLoginxjRChUvkacMeta?.path ?? "/confirmLogin",
    meta: confirmLoginxjRChUvkacMeta || {},
    alias: confirmLoginxjRChUvkacMeta?.alias || [],
    redirect: confirmLoginxjRChUvkacMeta?.redirect,
    component: () => import("/vercel/path0/pages/confirmLogin.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93QJUHw5g7hHMeta?.name ?? "info-slug",
    path: _91_46_46_46slug_93QJUHw5g7hHMeta?.path ?? "/info/:slug(.*)*",
    meta: _91_46_46_46slug_93QJUHw5g7hHMeta || {},
    alias: _91_46_46_46slug_93QJUHw5g7hHMeta?.alias || [],
    redirect: _91_46_46_46slug_93QJUHw5g7hHMeta?.redirect,
    component: () => import("/vercel/path0/pages/info/[...slug].vue").then(m => m.default || m)
  },
  {
    name: logint0AWlhQgM0Meta?.name ?? "login",
    path: logint0AWlhQgM0Meta?.path ?? "/login",
    meta: logint0AWlhQgM0Meta || {},
    alias: logint0AWlhQgM0Meta?.alias || [],
    redirect: logint0AWlhQgM0Meta?.redirect,
    component: () => import("/vercel/path0/pages/login.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93Zf86rRDqqNMeta?.name ?? "news-slug",
    path: _91_46_46_46slug_93Zf86rRDqqNMeta?.path ?? "/news/:slug(.*)*",
    meta: _91_46_46_46slug_93Zf86rRDqqNMeta || {},
    alias: _91_46_46_46slug_93Zf86rRDqqNMeta?.alias || [],
    redirect: _91_46_46_46slug_93Zf86rRDqqNMeta?.redirect,
    component: () => import("/vercel/path0/pages/news/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexxeWP9UFz4RMeta?.name ?? "news",
    path: indexxeWP9UFz4RMeta?.path ?? "/news",
    meta: indexxeWP9UFz4RMeta || {},
    alias: indexxeWP9UFz4RMeta?.alias || [],
    redirect: indexxeWP9UFz4RMeta?.redirect,
    component: () => import("/vercel/path0/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93PiEiFSMuFXMeta?.name ?? "pages-slug",
    path: _91_46_46_46slug_93PiEiFSMuFXMeta?.path ?? "/pages/:slug(.*)*",
    meta: _91_46_46_46slug_93PiEiFSMuFXMeta || {},
    alias: _91_46_46_46slug_93PiEiFSMuFXMeta?.alias || [],
    redirect: _91_46_46_46slug_93PiEiFSMuFXMeta?.redirect,
    component: () => import("/vercel/path0/pages/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: overview3h72kTsVpVMeta?.name ?? "profile-overview",
    path: overview3h72kTsVpVMeta?.path ?? "/profile/overview",
    meta: overview3h72kTsVpVMeta || {},
    alias: overview3h72kTsVpVMeta?.alias || [],
    redirect: overview3h72kTsVpVMeta?.redirect,
    component: () => import("/vercel/path0/pages/profile/overview.vue").then(m => m.default || m)
  },
  {
    name: settingsOrFU0tYfAWMeta?.name ?? "profile-settings",
    path: settingsOrFU0tYfAWMeta?.path ?? "/profile/settings",
    meta: settingsOrFU0tYfAWMeta || {},
    alias: settingsOrFU0tYfAWMeta?.alias || [],
    redirect: settingsOrFU0tYfAWMeta?.redirect,
    component: () => import("/vercel/path0/pages/profile/settings.vue").then(m => m.default || m)
  },
  {
    name: resetpasswordtzshyXK4M4Meta?.name ?? "resetpassword",
    path: resetpasswordtzshyXK4M4Meta?.path ?? "/resetpassword",
    meta: resetpasswordtzshyXK4M4Meta || {},
    alias: resetpasswordtzshyXK4M4Meta?.alias || [],
    redirect: resetpasswordtzshyXK4M4Meta?.redirect,
    component: () => import("/vercel/path0/pages/resetpassword.vue").then(m => m.default || m)
  },
  {
    name: signupY93ILdpGpbMeta?.name ?? "signup",
    path: signupY93ILdpGpbMeta?.path ?? "/signup",
    meta: signupY93ILdpGpbMeta || {},
    alias: signupY93ILdpGpbMeta?.alias || [],
    redirect: signupY93ILdpGpbMeta?.redirect,
    component: () => import("/vercel/path0/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93x81oV3TSaaMeta?.name ?? "stories-slug",
    path: _91_46_46_46slug_93x81oV3TSaaMeta?.path ?? "/stories/:slug(.*)*",
    meta: _91_46_46_46slug_93x81oV3TSaaMeta || {},
    alias: _91_46_46_46slug_93x81oV3TSaaMeta?.alias || [],
    redirect: _91_46_46_46slug_93x81oV3TSaaMeta?.redirect,
    component: () => import("/vercel/path0/pages/stories/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexTZwyp4rywJMeta?.name ?? "stories",
    path: indexTZwyp4rywJMeta?.path ?? "/stories",
    meta: indexTZwyp4rywJMeta || {},
    alias: indexTZwyp4rywJMeta?.alias || [],
    redirect: indexTZwyp4rywJMeta?.redirect,
    component: () => import("/vercel/path0/pages/stories/index.vue").then(m => m.default || m)
  }
]